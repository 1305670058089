import styled from 'styled-components'

export const ContactWrapper = styled.section`
  position: relative;
  width: 100%;
  background: var(--white-3);
`

export const ContactContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const ContactInfoContainer = styled.div`
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  width: 100%;
`

export const ContactInfo = styled.div`
  width: 100%;
`

export const ContactHeading = styled.h1`
  display: inline-block;
  margin-bottom: 40px;
  padding-bottom: 7px;
  color: var(--gray);
  font-size: 26px;
  font-weight: bold;
  border-bottom: 4px solid var(--primary);
`

export const ContactSingle = styled.div`
  display: flex;
  margin-bottom: 30px;
`

export const ContactDescription = styled.span`
  color: var(--gray-2);
`

export const ContactLink = styled.a`
  color: var(--gray-2);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const ContactIcon = styled.span`
  display: inline-block;
  margin-right: 15px;
  font-size: 17px;
  color: var(--gray-2);
`

export const ContactImg = styled.img`
  width: 100%;
  height: auto;
`

