import React from 'react'
import Hero from './Hero'
import About from './About'
import Services from './Services'
import Portofolios from './Portofolios'
import Contact from './Contact'

function Pages() {
    return (
        <main>
            <Hero />
            <About />
            <Services />
            <Portofolios />
            <Contact />
        </main>
    )
}

export default Pages
