import styled from 'styled-components'
import { Link as LinkScroll } from 'react-scroll'

export const SidebarWrapper = styled.aside`
  @media screen and (min-width: 768px) {
    display: none;
  }
  position: fixed;
  left: 0;
  top: ${props => props.isActive ? '0' : '-100%'};
  width: 100%;
  height: 100%;
  background: var(--white);
  opacity: ${props => props.isActive ? '1' : '0'};
  transition: all 300ms ease;
  z-index: 110;
`

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const CloseIcon = styled.div`
  position: absolute;
  display: inline-block;
  right: 21px;
  top: 22px;
  color: var(--black);
  font-size: 2.2rem;
  cursor: pointer;
`

export const SidebarMenu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: none;
`

export const SidebarItem = styled.li`
  margin: 10px 0;
`

export const SidebarLink = styled(LinkScroll)`
  display: block;
  padding: 5px 10px;
  color: var(--black);
  font-size: 20px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: var(--primary);
  }
`

export const SidebarBtn = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  transition: all 200ms ease-in-out;
`

export const SidebarBtnLink = styled.a`
  display: inline-block;
  padding: .5rem 1.25rem;
  margin: 10px 0;
  color: var(--white);
  background: var(--primary);
  font-size: 20px;
  border: none;
  border-radius: 50px;
  outline: none;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    background: var(--gray);
  }
`