import React, { useContext } from 'react'
import { NavbarContext } from '../../stores/Navbar.context'
import {
  SidebarWrapper,
  SidebarContainer,
  CloseIcon,
  SidebarMenu,
  SidebarItem,
  SidebarLink,
  SidebarBtn,
  SidebarBtnLink
} from './Sidebar.elements'
import { FaTimes } from 'react-icons/fa'

function Sidebar() {
  const { 
    useLinks,
    useIsActive 
  } = useContext(NavbarContext)
  const links = useLinks
  const [isActive, setActive] = useIsActive

  function toggleActive() {
    setActive(!isActive)
    if (isActive === false) document.body.style.overflowY = 'hidden'
    else document.body.style.overflowY = ''
  }

  return (
    <SidebarWrapper isActive={isActive}>
      <SidebarContainer className="container">
        <CloseIcon onClick={toggleActive}>
          <FaTimes />
        </CloseIcon>
        <SidebarMenu>
          {
            links.map((link, index) => {
              return (
                <SidebarItem key={index}>
                  <SidebarLink
                    to={link.href}
                    smooth={true}
                    duration={700}
                    spy={true}
                    exact='true'
                    offset={-70}
                    onClick={toggleActive}
                  >
                    {link.title}
                  </SidebarLink>
                </SidebarItem>
              )
            })
          }
        </SidebarMenu>
        <SidebarBtn>
          <SidebarBtnLink href='https://blog.devstudioid.com' target='_blank'>Visit Blog</SidebarBtnLink>
        </SidebarBtn>
      </SidebarContainer>
    </SidebarWrapper>
  )
}

export default Sidebar

