import styled from 'styled-components'
import { Link as LinkScroll } from 'react-scroll'

export const NavbarWrapper = styled.nav`
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  width: 100%;
  height: ${props => props.isScrolled ? 'var(--navbar-height)' : 'calc(var(--navbar-height) + 20px)'};
  font-size: 1rem;
  background: ${props => props.isScrolled ? 'var(--white)' : 'transparent'};
  box-shadow: ${props => props.isScrolled ? '0 .125rem .25rem rgba(0,0,0,.075)' : '0 .125rem .25rem rgba(0,0,0,0)'};
  z-index: 100;
  transition: all 700ms ease;
`

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const NavbarLogo = styled.a`
  display: inline-block;
  margin-top: 5px;
  cursor: pointer;
`

export const NavbarLogoImg = styled.img`
  width: 170px;
  height: auto;
`

export const MobileIcon = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }

  position: relative;
  display: inline-block;
  color: var(--gray);
  font-size: 1.8rem;
  cursor: pointer;
`

export const NavbarMenu = styled.ul`
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    margin-left: auto;
    text-align: center;
    list-style: none;
  }
  display: none;
`

export const NavbarItem = styled.li`
  height: 100%;
`

export const NavbarLinks = styled(LinkScroll)`
  position: relative;
  display: inline-block;
  height: 100%;
  padding: 10px 1rem;
  font-size: 15px;
  color:  var(--black);
  text-decoration: none;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    display: block;
    left: 50%;
    bottom: 0;
    width: 0;
    height: 3px;
    background: var(--primary);
    opacity: 0;
    transform: translateX(-50%);
    transition: all 200ms ease-in-out;
  }
  
  &.active::after {
    width: calc(100% - 2rem);
    opacity: 1;
  }
`

export const NavbarBtn = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    margin-left: 16px;
  }
  display: none;
`

export const NavbarBtnLink = styled.a`
  display: inline-block;
  padding: 8px 17px;
  color: var(--white);
  background: var(--primary);
  font-size: 15px;
  border: none;
  border-radius: 50px;
  outline: none;
  white-space: nowrap;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    background: var(--gray);
  }
`