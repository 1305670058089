import React, { useContext } from 'react'
import { NavbarContext } from '../../stores/Navbar.context'
import {
  BackTopWrapper,
  BackTopIcon
} from './BackTopElements'
import {
  FaAngleUp
} from 'react-icons/fa'

function BackTop() {
  const { useIsActive, useIsPassed } = useContext(NavbarContext)
  const setActive = useIsActive[1]
  const isPassed = useIsPassed

  function scrollToTop(e) {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  function hideSidebar() {
    setActive(false)
    document.body.style.overflowY = ''
  }

  return (
    <BackTopWrapper
      className="fadeInUp" 
      isPassed={isPassed} 
      onClick={(event) => {
        scrollToTop(event); 
        hideSidebar();
      }}
    >
      <BackTopIcon>
        <FaAngleUp />
      </BackTopIcon>
    </BackTopWrapper>
  )
}

export default BackTop
