import React from 'react';
import {
  AboutWrapper,
  AboutContainer,
  AboutInfoContainer,
  AboutInfo,
  AboutHeading,
  AboutDescription,
  AboutVideo,
  AboutBtn
} from './About.elements';

function About() {
  return (
    <AboutWrapper id="about" className='section-shadow'>
      <AboutContainer className='container'>
        <AboutInfoContainer>
          <AboutInfo>
            <AboutHeading>About</AboutHeading>
            <AboutDescription>
              Setiap situs web dirancang khusus untuk mengakomodasi kebutuhan yang 
              didorong oleh aspirasi dan tujuan bisnis Anda,
              dan kami mengemasnya dengan cara yang kreatif namun efektif sehingga audiens
              yang ditargetkan dapat dengan mudah mencerna pesan Anda.
            </AboutDescription>
            <AboutDescription>
              Kami adalah sebuah tim yang mengerjakan berbagai macam proyek pembuatan aplikasi Website dan Multimedia.
            </AboutDescription>
            <AboutBtn
              to='services'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-70}
              className='shadow'
            >
              What do we offer
            </AboutBtn>
          </AboutInfo>
          <AboutInfo>
            <AboutVideo>
              <iframe 
              title="promo"
              width="100%" 
              height="300" 
              src="https://www.youtube.com/embed/ntq4SDnRkl4" 
              frameBorder="0" 
              allow="accelerometer; 
                autoplay; 
                clipboard-write; 
                encrypted-media; 
                gyroscope; 
                picture-in-picture" 
                allowFullScreen
              ></iframe>
            </AboutVideo>
          </AboutInfo>
        </AboutInfoContainer>
      </AboutContainer>
    </AboutWrapper>
  )
};

export default About;
