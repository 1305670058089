import styled from 'styled-components'

export const BackTopWrapper = styled.div`
  position: fixed;
  display: ${props => props.isPassed ? 'inline-block' : 'none'};
  bottom: 35px;
  right: 35px;
  padding: 4px 8px;
  background: var(--primary);
  border-radius: 2px;
  z-index: 120;
  cursor: pointer;
`

export const BackTopIcon = styled.span`
  font-size: 26px;
  color: var(--white);
`