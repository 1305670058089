import React from 'react'
import {
  FooterWrapper,
  FooterContainer,
  FooterInfoContainer,
  FooterCard,
  FooterHeader,
  FooterDescription,
  FooterSocial,
  FooterSocialItem,
  FooterSocialLink,
  FooterSocialIcon,
  FooterSocialDescription,
  FooterCopyright
} from './Footer.elements'
import {  
  FaFacebook,
  FaWhatsapp
} from 'react-icons/fa'
import {
  FiInstagram
} from 'react-icons/fi'

function Footer() {
  return (
    <FooterWrapper className='section-shadow'>
      <FooterContainer className='container'>
        <FooterInfoContainer>
          <FooterCard>
            <FooterHeader>Dev Studio ID</FooterHeader>
            <FooterDescription>Mengerjakan berbagai macam proyek pembuatan aplikasi website (Company Profile, E-Commerce, E-Learning, dsb) dan video (3D video, Motion Graphic, dsb).</FooterDescription>
          </FooterCard>
          {/* <FooterCard>
            &nbsp;
          </FooterCard> */}
          <FooterCard>
            <FooterHeader>Socials</FooterHeader>
            <FooterSocial>
              <FooterSocialItem>
                <FooterSocialLink facebook href="https://www.facebook.com/Development-Studio-ID-103489338001293" target="_blank">
                  <FooterSocialIcon><FaFacebook /></FooterSocialIcon>
                  <FooterSocialDescription>Dev Studio ID</FooterSocialDescription>
                </FooterSocialLink>
              </FooterSocialItem>
              <FooterSocialItem>
                <FooterSocialLink instagram href="https://www.instagram.com/devstudioid/" target="_blank">
                  <FooterSocialIcon><FiInstagram /></FooterSocialIcon>
                  <FooterSocialDescription>Dev Studio ID</FooterSocialDescription>
                </FooterSocialLink>
              </FooterSocialItem>
              <FooterSocialItem>
                <FooterSocialLink whatsapp href="https://wa.me/6285775300525" target="_blank">
                  <FooterSocialIcon><FaWhatsapp /></FooterSocialIcon>
                  <FooterSocialDescription>0857 7530 0525 (Bayu)</FooterSocialDescription>
                </FooterSocialLink>
              </FooterSocialItem>
            </FooterSocial>
          </FooterCard>
        </FooterInfoContainer>
      </FooterContainer>
      <FooterCopyright>
        <FooterContainer>
          Copyright &copy; 2020. Dev Studio ID
        </FooterContainer>
      </FooterCopyright>
    </FooterWrapper>
  )
}

export default Footer
