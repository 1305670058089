import React from 'react'
import {
	ContactWrapper,
  ContactContainer,
  ContactInfoContainer,
  ContactInfo,
  ContactHeading,
  ContactSingle,
  ContactIcon,
  ContactDescription,
  ContactLink,
  ContactImg
} from './Contact.elements'
import { 
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhoneAlt
} from 'react-icons/fa'
import ContactImgFile from '../../images/contact.svg'

function Contact() {
	return (
		<ContactWrapper id="contact">
      <ContactContainer className="container">
        <ContactInfoContainer>
          <ContactInfo>
            <ContactHeading>Contact</ContactHeading>
            <ContactSingle>
              <ContactIcon><FaMapMarkerAlt /></ContactIcon>
              <ContactDescription>JL. Bangun Cipta Sarana, Kelapa Gading, Jakarta Utara</ContactDescription>
            </ContactSingle>
            <ContactSingle>
              <ContactIcon><FaEnvelope /></ContactIcon>
              <ContactDescription><ContactLink href="mailto:marketing@devstudioid.com">marketing@devstudioid.com</ContactLink></ContactDescription>
            </ContactSingle>
            <ContactSingle>
              <ContactIcon><FaPhoneAlt /></ContactIcon>
              <ContactDescription><ContactLink href="tel:6285775300525">0857 7530 0525 (Bayu)</ContactLink></ContactDescription>
            </ContactSingle>
          </ContactInfo>
          <ContactInfo>
            <ContactImg src={ContactImgFile}></ContactImg>
          </ContactInfo>
        </ContactInfoContainer>
      </ContactContainer>
    </ContactWrapper>
	)
}

export default Contact
