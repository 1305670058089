import React, { Fragment } from 'react';
import './App.css';
import { NavbarProvider } from './stores/Navbar.context'
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import BackTop from './components/BackTop'
import Footer from './components/Footer'
import Sections from './sections';

function App() {
  return (
    <Fragment>
      <NavbarProvider>
        <Navbar />
        <Sidebar />
        <BackTop />
      </NavbarProvider>
      <Sections />
      <Footer />
    </Fragment>
  );
}

export default App;
