import React from 'react'
import {
  ServiceWrapper,
  ServiceContainer,
  ServiceHeader,
  ServiceHeading,
  ServiceInfoContainer,
  ServiceCard,
  ServiceCardHeader,
  ServiceCardBody,
  ServiceImg,
  ServiceTitle,
  ServiceDescription,
  ServiceBtnMenu,
  ServiceBtn
} from './Services.elements';
import ComprofImgFile from '../../images/company-profile.jpg';
import ECommerceImgFile from '../../images/e-commerce.jpg';
import WeddingImgFile from '../../images/wedding.jpg';
import MultimediaImgFile from '../../images/multimedia.jpg';

function Services() {
  const serviceInfos = [
    {
      title: 'Company Profile',
      description: 'Hadirkan website perusahaanmu agar semakin dikenali masyarakat dengan menginformasikan profil tentang perusahaan Anda.',
      img: ComprofImgFile
    },
    {
      title: 'E-Commerce',
      description: 'Luaskan jangkauan toko Anda dengan menghadirkan website agar memudahkan pelanggan untuk membeli produk yang Anda tawarkan.',
      img: ECommerceImgFile
    },
    {
      title: 'Wedding Invitation',
      description: 'Memberikan kemudahan informasi mengenai hari spesial anda. Jarak tidak akan menjadi hambatan, kami dapat membuatkan undangan digital sesuai dengan yang anda inginkan.',
      img: WeddingImgFile
    },
    {
      title: 'Multimedia',
      description: 'Buat design graphic, video, motion graphic, dan animasi 3d sebagai media advertisement untuk kebutuhan perusahaan atau lembaga Anda.',
      img: MultimediaImgFile
    }
  ]
  return (
    <ServiceWrapper id="services">
      <ServiceContainer className="container">
        <ServiceHeader>
          <ServiceHeading>Services</ServiceHeading>
        </ServiceHeader>
        <ServiceInfoContainer>
          {
            serviceInfos.map((info, index) => {
              return (
                <ServiceCard 
                  key={index} 
                  className='shadow'
                >
                  <ServiceCardHeader>
                    <ServiceImg src={info.img} />
                  </ServiceCardHeader>
                  <ServiceCardBody>
                    <ServiceTitle>{info.title}</ServiceTitle>
                    <ServiceDescription>{info.description}</ServiceDescription>
                    {/* <ServiceBtn href={info.href} target="_blank">Order Now</ServiceBtn> */}
                  </ServiceCardBody>
                </ServiceCard>
              )
            })
          }
        </ServiceInfoContainer>
        <ServiceBtnMenu>
          <ServiceBtn 
            className='shadow'
            href='https://wa.me/6285775300525'
            target='_blank'
          >
            Order your own now
          </ServiceBtn>
        </ServiceBtnMenu>
      </ServiceContainer>
    </ServiceWrapper>
  )
}

export default Services
