import React from 'react'
import {
  HeroWrapper,
/*   HeroCaption,
  HeroContainer,
  HeroSubHeadding,
  HeroHeadding,
  HeroBtnMenu,
  HeroBtn, */
  HeroBg,
  VideoBg
} from './Hero.elements'
import HeroVideo from '../../videos/hero.mp4'

function Hero() {
  return (
    <HeroWrapper id='hero'>
{/*       <HeroCaption>
        <HeroContainer className='container'>
          <HeroHeadding>Studio Developer</HeroHeadding>
          <HeroSubHeadding>Mengerjakan berbagai macam proyek pembuatan aplikasi website (Company Profile, E-Commerce, E-Learning, dsb) dan video (3D video, Motion Graphic, dsb).
          </HeroSubHeadding>
          <HeroBtnMenu>
            <HeroBtn
              primary='true'
              to='services'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-70}
            >
              Our Services
            </HeroBtn>
            <HeroBtn
              to='about'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-70}
            >
              About Us
            </HeroBtn>
          </HeroBtnMenu>
        </HeroContainer>
      </HeroCaption> */}
      <HeroBg>
        <VideoBg autoPlay loop muted src={HeroVideo} type='video/mp4' />
      </HeroBg>
    </HeroWrapper>
  )
}

export default Hero