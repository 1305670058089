import styled from 'styled-components'

export const ServiceWrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  background: var(--white-3);
`

export const ServiceContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const ServiceHeader = styled.div`
  width: 100%;
  margin-bottom: 40px;
  text-align: center;
`

export const ServiceHeading = styled.h1`
  display: inline-block;
  padding-bottom: 7px;
  text-align: center;
  color: var(--gray);
  font-size: 30px;
  border-bottom: 4px solid var(--primary);
`

export const ServiceInfoContainer = styled.div`
  @media screen and (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
  }
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 30px;
  width: 100%;
`

export const ServiceCard = styled.div`
  position: relative;
  background: var(--white);
  border-radius: 10px;
`

export const ServiceCardHeader = styled.div`
  width: 100%;
  padding: 15px;
  padding-bottom: 10px;
`

export const ServiceCardBody = styled.div`
  padding: 15px;
  padding-bottom: 25px;
`

export const ServiceImg = styled.img`
  display: inline-block;
  width: 100%;
  height: auto;
`

export const ServiceTitle = styled.h2`
  display: block;
  color: var(--gray);
  font-size: 20px;
  margin-bottom: 15px;
`

export const ServiceDescription = styled.p`
  color: var(--gray-2);
  margin-bottom: 16px;
  font-size: 14px;
`

export const ServiceBtnMenu = styled.div`
  display: block;
  margin-top: 40px;
  text-align: center;
`

export const ServiceBtn = styled.a`
  display: inline-block;
  padding: 10px 22px;
  color: var(--white);
  background: var(--primary);
  border: none;
  border-radius: 50px;
  outline: none;
  white-space: nowrap;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    background: var(--black);
  }
`