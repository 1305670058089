import React, { useContext } from 'react'
import {
  NavbarWrapper,
  NavbarContainer,
  NavbarLogo,
  NavbarLogoImg,
  MobileIcon,
  NavbarMenu,
  NavbarItem,
  NavbarLinks,
  NavbarBtn,
  NavbarBtnLink
} from './Navbar.elements'
import { NavbarContext } from '../../stores/Navbar.context'
import Logo from '../../images/logo-b.png'
import { FaBars } from 'react-icons/fa'

function Navbar() {
  const { 
    useLinks, 
    useIsActive,
    useIsScrolled
  } = useContext(NavbarContext) 
  const links = useLinks
  const [isActive, setActive] = useIsActive
  const isScrolled = useIsScrolled

  function toggleActive() {
    setActive(!isActive)
    if (isActive === false) document.body.style.overflowY = 'hidden'
    else document.body.style.overflowY = ''
  }
  
  function scrollToTop(e) {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <NavbarWrapper isScrolled={isScrolled}>
      <NavbarContainer className='container'>
        <NavbarLogo
          href="#"
          onClick={(event) => scrollToTop(event)}
        >
          <NavbarLogoImg src={Logo}></NavbarLogoImg>
        </NavbarLogo>
        <MobileIcon className={isActive ? 'active' : ''} onClick={toggleActive}>
          <FaBars />
        </MobileIcon>
        <NavbarMenu>
          {
            links.map((link, index) => {
              return (
                <NavbarItem key={index}>
                  <NavbarLinks
                    to={link.href}
                    smooth={true}
                    duration={700}
                    spy={true}
                    exact='true'
                    offset={-70}
                    activeClass='active'
                  >
                    {link.title}
                  </NavbarLinks>
                </NavbarItem>
              )
            })
          }
        </NavbarMenu>
        <NavbarBtn>
          <NavbarBtnLink href='https://blog.devstudioid.com' target='_blank'>Visit Blog</NavbarBtnLink>
        </NavbarBtn>
      </NavbarContainer>
    </NavbarWrapper>
  )
}

export default Navbar
