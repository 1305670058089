import React, { createContext, useState, useEffect } from 'react'

export const NavbarContext = createContext()

export function NavbarProvider(props) {
  const links = [
    {
      title: 'About',
      href: 'about'
    },
    {
      title: 'Services',
      href: 'services'
    },
    {
      title: 'Portofolios',
      href: 'portofolios'
    },
    {
      title: 'Contact',
      href: 'contact'
    }
  ]

  const [isActive, setActive] = useState(false)
  const [isScrolled, setScrolled] = useState(false)
  const [isPassed, setPassed] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', toggleScrolled)
    window.addEventListener('scroll', togglePassed)
    return () => {
      window.removeEventListener('scroll', toggleScrolled)
      window.removeEventListener('scroll', togglePassed)
    }
  }, [])
  
  function toggleScrolled() {
    window.scrollY > 0 ? setScrolled(true) : setScrolled(false)
  }

  function togglePassed() {
    window.scrollY > window.innerHeight - 80 ? setPassed(true) : setPassed(false)
  }  

  return (
    <NavbarContext.Provider
      value={
        {
          useLinks: links,
          useIsActive: [isActive, setActive],
          useIsScrolled: isScrolled,
          useIsPassed: isPassed
        }
      }
    >
      {props.children}
    </NavbarContext.Provider>
  )
}
