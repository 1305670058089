import React, { useState } from 'react'
import {
  PortofolioWrapper,
  PortofolioContainer,
  PortofolioHeader,
  PortofolioHeading,
  PortofolioTabContainer,
  PortofolioTab,
  PortofolioTabItem,
  PortofolioTabLink,
  PortofolioTabCheckbox,
  PortofolioInfoContainer,
  PortofolioInfo,
  PortofolioImg,
  PortofolioCaption,
  PortofolioTitle,
  PortofolioDescription
} from './Portofolios.elements';

import WangsuhTrans from '../../images/wangsuh-trans.jpg'
import Omahngayomi from '../../images/omahngayomi.jpg'
import Zettef from '../../images/zettef.jpg'
import Ecom from '../../images/ecom.jpg'

function Portofolios() {
  let [websites, setWebsite] = useState([
    {
      title: 'Wangsuh Trans',
      description: 'Wangsuh Trans merupakan perusahaan yang bergerak di bidang jasa penyewaan mobil yang terus berkembang pesat dengan memberikan pelayanan terbaik.',
      img: WangsuhTrans,
      href: 'https://wangsuhtransindo.com/',
      isHover: false
    },
    {
      title: 'Omahngayomi',
      description: 'Aplikasi ini merupakan sebuah platform Social Caring demi memberikan pendidikan gratis bagi anak-anak putus sekolah.',
      img: Omahngayomi,
      href: 'https://omahngayomi.devstudioid.com/',
      isHover: false
    },
    {
      title: 'Zhenesia First',
      description: 'ZHENESIA FIRST adalah lembaga kursus yang fokus untuk mendampingi generasi penerus bangsa agar memiliki soft skill dan hard skill di masa depan.',
      img: Zettef,
      href: 'https://zettef.com/',
      isHover: false
    },
    {
      title: 'ZG Beauty',
      description: 'ZG Beauty adalah produk kecantikan baru yang diperkenalkan oleh penyanyi ternama Indonesia Zaskia Gotik. ZG Beauty tersebar di seluruh Indonesia bahkan merambah di mancanegara, dengan memiliki distributor, agen, member dan reseller resmi sebagai mitra.',
      img: Ecom,
      href: 'https://www.zgbeautyofficial.com/',
      isHover: false
    }
  ])
  let [active, setActive] = useState('website')
  let tabs = [
    {
      title: 'Websites',
      name: 'website'
    },
    {
      title: 'Animations',
      name: 'animation'
    }
  ]
  function toggleHover(index) {
    setWebsite(
      websites.map((website, i) =>
        i === index ? { ...website, isHover: !website.isHover } : { ...website, isHover: false }
      )
    )
  }
  function updateActive(event, name) {
    event.preventDefault()
    setActive(name)
  }
  return (
    <PortofolioWrapper id="portofolios" className='section-shadow'>
      <PortofolioContainer className="container">
        <PortofolioHeader>
          <PortofolioHeading>Portofolios</PortofolioHeading>
        </PortofolioHeader>
        <PortofolioTabContainer>
          <PortofolioTab>
            {
              tabs.map((tab, index) => {
                return (
                  <PortofolioTabItem key={index}>
                    <PortofolioTabLink
                      href="#"
                      className={`shadow ${active === tab.name ? 'active' : ''}`}
                      onClick={(event) => updateActive(event, tab.name)}
                    >
                      <PortofolioTabCheckbox className={active === tab.name ? 'active' : ''} />
                      <span>{tab.title}</span>
                    </PortofolioTabLink>
                  </PortofolioTabItem>
                )
              })
            }
          </PortofolioTab>
        </PortofolioTabContainer>
        <PortofolioInfoContainer
          className="fadeInUp"
          style={{ display: active === 'website' ? 'grid' : 'none' }}
        >
          {
            websites.map((website, index) => {
              return (
                <PortofolioInfo
                  className='shadow'
                  href={website.href}
                  target="_blank"
                  key={index}
                  onMouseEnter={() => toggleHover(index)}
                  onMouseLeave={() => toggleHover(index)}
                >
                  <PortofolioImg src={website.img} />
                  <PortofolioCaption isHover={website.isHover}>
                    <PortofolioTitle isHover={website.isHover}>{website.title}</PortofolioTitle>
                    <PortofolioDescription isHover={website.isHover}>{website.description}</PortofolioDescription>
                  </PortofolioCaption>
                </PortofolioInfo>
              )
            })
          }
        </PortofolioInfoContainer>
        <PortofolioInfoContainer
          className="fadeInUp"
          style={{ display: active === 'animation' ? 'grid' : 'none' }}
        >
          <PortofolioInfo className='shadow'>
            <iframe 
              title="showreel"
              width="100%"
              height="100%" 
              src="https://www.youtube.com/embed/XSni2uJQR5w" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
            ></iframe>
          </PortofolioInfo>
          <PortofolioInfo className='shadow'>
            <iframe 
              title="ramadhan"
              width="100%" 
              height="100%" 
              src="https://www.youtube.com/embed/0l1cmfm_pZE" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
            ></iframe>
          </PortofolioInfo>
          <PortofolioInfo className='shadow'>
            <iframe 
              title="pln"
              width="100%" 
              height="100%" 
              src="https://www.youtube.com/embed/lQJMn7ammDc" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
            ></iframe>
          </PortofolioInfo>
          <PortofolioInfo className='shadow'>
            <iframe 
              title="xl-axiata"
              width="100%" 
              height="100%" 
              src="https://www.youtube.com/embed/-HzS_pqNALQ" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
            ></iframe>
          </PortofolioInfo>
          <PortofolioInfo className='shadow'>
            <iframe 
              title="garuda"
              width="100%" 
              height="100%" 
              src="https://www.youtube.com/embed/bMF-UHcKK3s" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
            ></iframe>
          </PortofolioInfo>
        </PortofolioInfoContainer>
      </PortofolioContainer>
    </PortofolioWrapper>
  )
}

export default Portofolios
