import styled from 'styled-components'
import {Link as LinkScroll} from 'react-scroll'

export const HeroWrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 0;
  background: var(--white-3);
`

export const HeroBg = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const VideoBg = styled.video`
  @media screen and (min-width: 576px) {
    height: 100%;
  }
  width: 100%;
  height: 60%;
  object-fit: cover;
  background: var(--white-3);
`

export const HeroCaption = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: var(--black);
  background: rgba(0, 0, 0, 0);
  z-index: 1;
`

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const HeroHeadding = styled.h1`
  @media screen and (min-width: 992px) {
    font-size: 34px;
  }
  @media screen and (min-width: 768px) {
    font-size: 32px;
  }
  display: block;
  max-width: 800px;
  margin-bottom: 16px;
  font-size: 30px;
`

export const HeroSubHeadding = styled.p`
  @media screen and (min-width: 992px) {
    font-size: 20px;
  }
  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
  display: block;
  max-width: 520px;
  margin-bottom: 24px;
  font-size: 16px;
`

export const HeroBtnMenu = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  grid-gap: 10px;
  width: 100%;
  height: auto;
`

export const HeroBtn = styled(LinkScroll)`
  display: inline-block;
  padding: 10px 22px;
  color: ${props => props.primary ? 'var(--white)': 'var(--black)'};
  background: ${props => props.primary ? 'var(--primary)': 'var(--white)'};
  border: none;
  border-radius: 50px;
  outline: none;
  white-space: nowrap;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    color: var(--primary);
    background: var(--white);
  }
`