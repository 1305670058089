import styled from 'styled-components'

export const PortofolioWrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  background: var(--white);
`

export const PortofolioContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const PortofolioHeader = styled.div`
  width: 100%;
  margin-bottom: 40px;
  text-align: center;
`

export const PortofolioHeading = styled.h1`
  display: inline-block;
  padding-bottom: 7px;
  text-align: center;
  color: var(--gray);
  font-size: 30px;
  border-bottom: 4px solid var(--primary);
`

export const PortofolioTabContainer = styled.div`
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 26px;
`

export const PortofolioTab = styled.ul`
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
`
export const PortofolioTabItem = styled.li`
  margin: 0 7px;
`

export const PortofolioTabLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: .5rem 1.25rem;
  border-radius: 50px;
  font-size: 15px;
  color: var(--gray-2);
  text-decoration: none;
  transition: all 200ms ease;
  &:hover {
    color: var(--primary);
  }
  &.active {
    color: var(--primary);
  }
`

export const PortofolioTabCheckbox = styled.span`
  position: relative;
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 10px;
  border-radius: 2px;
  background: transparent;
  border: 1px solid var(--gray-2);

  &::after {
    position: absolute;
    display: inline-block;
    content: '';
    top: 50%;
    left: 50%;
    font-weight: bold;
    color: var(--white);
    font-size: 10px;
    transform: translate(-50%, -50%);
  }

  &.active {
    background: var(--primary);
    border: 1px solid transparent;
    &::after{
      content: '\\2713';
    }
  }
`

export const PortofolioInfoContainer = styled.div`
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(300px, auto);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  width: 100%;
`

export const PortofolioInfo = styled.a`
  display: block;
  position: relative;
  text-decoration: none;
  border-radius: .25rem;
  overflow: hidden;
`

export const PortofolioImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const PortofolioCaption = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: center;
  background: ${props => props.isHover ? 'rgba(0, 0, 0, 0.85)' : 'rgba(0, 0, 0, 0)'};
  transition: all 200ms ease-in-out;
  z-index: 1;
`

export const PortofolioTitle = styled.h2`
  display: block;
  color: var(--white);
  font-size: 18px;
  opacity: ${props => props.isHover ? '1' : '0'};
  margin-bottom: 10px;
  transition: all 200ms ease-in-out;
`

export const PortofolioDescription = styled.p`
  color: var(--white);
  font-size: 12px;
  opacity: ${props => props.isHover ? '1' : '0'};
  transition: all 200ms ease-in-out;
`