import styled from 'styled-components'
import { Link as LinkScroll } from 'react-scroll'

export const AboutWrapper = styled.section`
  position: relative;
  width: 100%;
  background: var(--white);
`

export const AboutContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const AboutInfoContainer = styled.div`
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  width: 100%;
`

export const AboutInfo = styled.div`
  width: 100%;
`

export const AboutHeading = styled.h1`
  display: inline-block;
  margin-bottom: 40px;
  padding-bottom: 7px;
  color: var(--gray);
  font-size: 26px;
  font-weight: bold;
  border-bottom: 4px solid var(--primary);
`

export const AboutDescription = styled.p`
  margin-bottom: 30px;
  color: var(--gray-2);
`

export const AboutVideo = styled.div`
  @media screen and (min-width: 768px) {
    margin-top: 90px;
  }
  display: inline-block;
  width: 100%;
  height: auto;
`

export const AboutBtn = styled(LinkScroll)`
  display: inline-block;
  padding: 10px 22px;
  color: var(--white);
  background: var(--primary);
  border: none;
  border-radius: 50px;
  outline: none;
  white-space: nowrap;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    background: var(--black);
  }
`

