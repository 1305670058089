import styled, { css } from 'styled-components'

export const FooterWrapper = styled.footer`
  position: relative;
  width: 100%;
  padding-top: 40px;
  background: var(--white);
`

export const FooterContainer = styled.div`
  width: 100%;
  height: 100%;
  color: var(--gray-2);
`
export const FooterInfoContainer = styled.div`
  @media screen and (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  width: 100%;
`

export const FooterCard = styled.div`
  position: relative;
`

export const FooterHeader = styled.h2`
  display: block;
  margin-bottom: 20px;
  font-size: 18px;
  color: var(--gray);
`

export const FooterDescription = styled.p`
  display: block;
`

export const FooterSocial = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
` 

export const FooterSocialItem = styled.li`
  margin-bottom: 3px;
`

export const FooterSocialLink = styled.a`
  display: inline-block;
  color: var(--gray-2);
  text-decoration: none;
  transition: all 200ms ease;
  &:hover {
    ${props => props.facebook && css`
      color: 	#4267b2;
    `}
    ${props => props.instagram && css`
      color: 	#9932cc;
    `}
    ${props => props.whatsapp && css`
      color: 	#25d366;
    `}
  }
`

export const FooterSocialIcon = styled.span`
  display: inline-block;
  margin-right: 10px;
  font-size: 25px;
  vertical-align: middle;
`

export const FooterSocialDescription = styled.span`
  display: inline-block;
`

export const FooterCopyright = styled.div`
  display: block;
  margin-top: 30px;
  padding: 20px 0;
  background: var(--white);
  text-align: center;
  border-top: 1px solid var(--border-color);
`